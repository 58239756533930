import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage/local-storage.service';
const isRefreshing = new BehaviorSubject<boolean>(false);
const refreshTokenSubject = new BehaviorSubject<string | undefined>(undefined);

export const tokenInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthService);
    const toastService = inject(ToastService);
    const localStorageService = inject(LocalStorageService);
    const router: Router = inject(Router);
    const accessToken = localStorageService.getAccessToken();
    const refreshToken = localStorageService.getRefreshToken();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let r = request.clone();
    if (r.url.includes('/admin/auth/refresh') && refreshToken) {
        r = addToken(r, refreshToken);
    } else if (accessToken) {
        if (authService.isJwtExpired(accessToken, 30)) {
            return handleTokenExpired(r, next, authService, toastService, localStorageService, router);
        }
        r = addToken(r, accessToken);
    }
    return next(r).pipe(
        catchError((error: HttpErrorResponse) => {
            toastService.showErrorViaToast(toastService.formateErrorMessage(error));
            return throwError(() => error);
        }),
    );
};

const addToken = (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const handleTokenExpired = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
    authService: AuthService,
    toastService: ToastService,
    localStorageService: LocalStorageService,
    router: Router,
) => {
    if (!isRefreshing.value) {
        isRefreshing.next(true);
        refreshTokenSubject.next(undefined);
        if (authService.isLoggedIn) {
            const refreshToken = localStorageService.getAccessToken();
            return authService.refreshToken(refreshToken!).pipe(
                switchMap((response) => {
                    isRefreshing.next(false);
                    localStorageService.setTokens(response);
                    refreshTokenSubject.next(localStorageService.getAccessToken()!);
                    const newRequest = addToken(request, localStorageService.getAccessToken()!);
                    return next(newRequest);
                }),
                catchError((error: HttpErrorResponse) => {
                    toastService.showErrorViaToast(toastService.formateErrorMessage(error));
                    isRefreshing.next(false);
                    refreshTokenSubject.next(undefined);
                    localStorageService.clearLocalStorage();
                    authService.isLoggedIn.next(false);
                    router.navigate(['/auth']).then();
                    return throwError(() => error);
                }),
            );
        } else {
            isRefreshing.next(false);
            localStorageService.clearLocalStorage();
            router.navigate(['/auth']).then();
            return EMPTY;
        }
    } else {
        if (request.url.includes('admin/auth/refresh')) {
            authService.isLoggedIn.next(false);
            refreshTokenSubject.next(undefined);
            localStorageService.clearLocalStorage();
            router.navigate(['/auth']);
            return EMPTY;
        } else {
            return refreshTokenSubject.pipe(
                filter((token) => token !== undefined),
                take(1),
                switchMap((newAccessToken) => {
                    const newRequest = addToken(request, newAccessToken);
                    return next(newRequest);
                }),
            );
        }
    }
};
